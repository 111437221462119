.fondo-success-container {
    &__description {
        padding: 1.5rem;
        font-family: 'Helvetica Neue';
        height: 100%;
        position: relative;
        line-height: 1.6;

    }
    &__action {
        width: 70%;
        margin: auto;
        margin-top: 5%;
    }
}
