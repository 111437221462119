.sponsor-container {
    width: 100%;
    height: 100%;
    position: relative;

    &__hero-wrapper {
        background: #000;
        background-size: cover;
        background-position: 0 60%;
        width: 100%;
        height: 200px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    &__back-btn {
        cursor: pointer;
    }
    &__info-btn {
        cursor: pointer;
    }
    &__top-wrapper {
        display: flex;
        justify-content: space-between;
        width: 90%;
        padding: 20px 0;
    }

    &_balance-title {
        color: var(--white-color);
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 2px;
        text-transform: uppercase;
    }

    &_points {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.02em;
        color: var(--white-color);
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 60%;
        margin: 0 auto;
        margin-top: 17px;
    }

    &__content {
        margin-top: 30px;
        padding: 0 16px 45px;
    }

    &_content-title {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.02em;
        color: var(--black-color);
        width: 70%;
        margin: 0 auto;
    }

    &__donate-container {
        background-color: var(--pink-color);
        padding: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        border-radius: 6px;
        flex-direction: column;
    }

    &_number {
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    &_number-title {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 700;
        font-size: 70px;
        line-height: 85px;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #000;
        margin-bottom: -15px;
    }

    &_number-small {
        font-family: 'Helvetica Neue Bold';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #000;
    }
    &_number-m {
        margin-left: -17px;
    }

    &_rewilded {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #000;
        margin-top: 30px;
        text-transform: capitalize;
    }

    &__points-choose-wrapper {
        display: flex;
        gap: 5px;
        align-items: center;
        margin-top: 20px;
    }

    &_add-remove-button {
        cursor: pointer;
        width: 46px;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-size: 50px;
        border: none;
        background: none;
    }

    &__chosen-points {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &_choosen-points-number {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 700;
        font-size: 52px;
        line-height: 63px;
        text-align: center;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: #000;
    }

    &__bottom-wrapper {
        margin-top: 20%;
    }
}
.sponsor_points {
    padding: 0 0 0 18px;
}
