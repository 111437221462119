.fondo-container {
    &__description {
        padding: 1.5rem;
        font-family: 'Helvetica Neue';
        height: 100%;
        position: relative;
        line-height: 1.6;

    }
    &__action {
        width: 70%;
        margin: auto;
        margin-top: 5%;
    }
    &__action-label {
            text-align: center;  
            padding: 1.5rem;
    }
}
.signup-container {
    height: 95vh;
    &__signup-frame {
        width: 100%;
        height: 90%;
        border: none;

      }
}