* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.animated {
    position: relative;
    z-index: 3;
}

.shop-screen_disabled {
    width: 100%;
    &__input-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
    }
    &__inputs {
        position: relative;
        margin: 32px 0 32px 0;
        display: flex;
        gap: 8px;
        padding: 5px;
        justify-content: center;
        align-items: center;
        height: 55px;
        width: 740px;
        max-width: 771px;
        min-width: 300px;
        @media (max-width: 770px) {
            max-width: 440px;
            width: 440px;
        }
        @media (max-width: 585px) {
            max-width: 390px;
            width: 390px;
        }
        @media (max-width: 425px) {
            max-width: 330px;
            width: 330px;
        }
    }
    &__input {
        position: relative;
        height: 38px;
        border-radius: 32px;
        padding: 0 0 0 50px;
        border: 1px solid #000;
        width: 100%;
        font-size: 14px;
        @media screen and (max-width: 650px) {
            font-size: 0px;
        }
    }
    &__input {
        outline: none;
    }
    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 12px;
        margin-bottom: 24px;
    }
    &_icon {
        position: absolute;
        left: 25px;
        margin: 2px 0 0 0;
    }
    &__photos-container {
        max-width: 1440px;
        position: fixed;
        left: 11.5%;
        margin-right: auto;
        margin-left: auto;
        padding: 0;
        @media screen and (min-width: 1440px) {
            max-width: 1440px;
            margin-right: auto;
            margin-left: auto;
        }
    }
    &__photos {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-start;
        margin-right: 24px;
        margin-bottom: 64px;
        margin-left: 24px;
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        @media screen and (min-width: 1480px) {
            max-width: 1440px;
            margin-right: auto;
            margin-left: auto;
        }
        @media screen and (max-width: 767px) {
            grid-column-gap: 16px;
            grid-row-gap: 16px;
        }
        @media screen and (max-width: 335px) {
            grid-column-gap: 16px;
            grid-row-gap: 16px;
        }
    }
    &_photo {
        cursor: pointer;
        object-fit: cover;
        min-height: 100%;
        min-width: 100%;
    }
    &__img-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    &__img-container picture {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }
    &__photo-container {
        padding: 0 15px;
        border-radius: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background: var(--gasoline-color);
        height: 270px;
        margin-bottom: 14px;
        flex-basis: 100%;
        max-width: 23%;
        @media only screen and (max-width: 1010px) {
            max-width: 31%;
            min-width: 31%;
        }
        @media only screen and (max-width: 750px) {
            max-width: 48%;
            min-width: 48%;
        }
        @media only screen and (max-width: 500px) {
            max-width: 100%;
            min-width: 100%;
        }
    }
    &__photo-name {
        position: relative;
        font-style: normal;
        font-family: 'Helvetica Neue Bold';
        font-weight: 700;
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-align: center;
        padding: 0 10px 25px;
        color: #fff;
        z-index: 2;
    }
    &__photo-button-img {
        background-color: white;
        border-radius: 100%;
    }
}
.shop-screen {

    &__input-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
    }
    &__rewards-container {
        display: flex;
        align-items: center;
        border-radius: 24px;
        border: 2px solid #f7d4c7;
        &__text {
            width: 20px;
            font-family: 'Helvetica Neue Bold';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.001em;
            color: #000000;
            margin: 0 10px 0 8px;
            @media screen and (max-width: 500px) {
                margin: 0 2px 0 2px;
            }
        }
    }
    &__inputs {
        position: relative;
        margin: 32px 0 32px 0;
        display: flex;
        gap: 8px;
        padding: 0px 10px;
        justify-content: center;
        align-items: center;
        height: 55px;
        width: 60%;

        @media (max-width:800px){
            width: 90%;
        }

        @media (max-width:500px){
            width: 100%;
        }
    }
    &__input {
        position: relative;
        display: flex;
        justify-content: space-between;
        gap: 5px;
        align-items: center;    
        height: 38px;
        border-radius: 32px;
        padding-left: 10px;
        border: 1px solid var(--pink-color);
        color: #000;
        width: 100%;
        font-size: 14px;

        & > input{
            width: 80%;
            font-size: 14px;
            border: none;
            outline: none;
        }

        @media screen and (max-width: 650px) {
            font-size: 14px;
        }
    }

    &__country-selector {
        position: relative;
        display: inline-block;
        width: 70px;
    
        & button {
            cursor: pointer;
            width: 100%;
            padding: 10px 0px;
            display: flex;
            align-items: center;
            background-color: transparent;
            border: none;
            font-size: 1rem;
            gap: 5px;

            & img {
                width: 30px;
            }
        }
    
        & ul {
            position: absolute;
            top: 55%; 
            left: 0;
            width: 100%;
            list-style: none;
            background-color: white;
            border: 1px solid #ccc;
            display: flex;
            flex-direction: column;
            padding: 0;
            z-index: 6;  
        }
    
        & li {
            padding: 5px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            & img {
                width: 25px;
            }
            
            &:hover {
                background-color: #f0f0f0;
            }
        }
    }

    &__input {
        outline: none;
    }
    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 12px;
        margin-bottom: 24px;
    }
    &_search-delete {
        cursor: pointer;
        font-size: 14px;
        color: #ffa9a3;
        font-weight: 700;
    }
    &__photos-container {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        max-width: 1440px;
        position: relative;
        padding: 0;
        @media screen and (min-width: 1440px) {
            max-width: 1440px;
            margin-right: auto;
            margin-left: auto;
        }
    }
    &__photos {
        display: grid;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 64px;
        padding: 0 15px;
        gap: 18px;
        grid-template-columns: repeat(6, 200px);
            
        @media screen and (max-width: 1300px) {
          grid-template-columns: repeat(5, 200px);
        }

        @media screen and (max-width: 1100px) {
          grid-template-columns: repeat(4, 200px);
        }

        @media screen and (max-width: 870px) {
          grid-template-columns: repeat(3, 200px);
        }

        @media screen and (max-width: 650px) {
          grid-template-columns: repeat(2, 200px);
        }

        @media screen and (max-width: 440px) {
          grid-template-columns: repeat(2, 49%);
          padding: 0 10px;
          gap: 10px;
        }
    
      }
      
    &_photo {
        cursor: pointer;
        object-fit: cover;
        position: relative;
        min-height: 100%;
        min-width: 100%;
        height: 200px;
        border-radius: 50px;
        border: 3px solid black;
    }

    &__photo-site {
        display: flex;
        position: absolute;
        bottom: 0px;
        left: 0;
        justify-content: center;
        gap: 10px;
        height: 20%;
        width: 90%;
        background-color: white;

        & > a {
            font-weight: 600;
            font-size: 1.4rem;
            text-decoration: none;
            color: black;
        }
    }

    &__photo-percentage {
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: #ffa9a3;
        color: white;
        border-radius: 50%;
        padding: 30px;
        font-weight: bold;
        font-size: 1rem;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__photo-name {
        cursor: pointer;
        position: relative;
        font-style: normal;
        font-family: 'Helvetica Neue Bold';
        font-weight: 700;
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-align: center;
        padding: 0 10px 25px;
        color: #fff;
        z-index: 2;
    }
    &__photo-button-img {
        background-color: white;
        border-radius: 100%;
    }

    .selector-icon{
        width: 30px;
    }
}
