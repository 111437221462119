.transaction-container {
    height: 100%;
    position: relative;

    &__content {
        padding: 20px 16px;
    }
}

.transaction-balance {
    padding: 16px 21px;
    border-bottom: 2px solid var(--light-gray-color);

    &__info {
        display: flex;
        margin: 8px;
        justify-content: space-between;
    }
}
