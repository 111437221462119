.menu {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.menu_profile-bg {
    position: relative;
    width: 100%;
    height: 70px;
    &_photo {
        width: 180%;
        height: 100%;
    }
}

.menu__buttons-container {
    width: 100%;
}
.menu__buttons {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 3px 0 13px;
    border: 0px solid #fff;
    border-bottom: 1px solid rgba(155, 155, 155, 0.2);
    background-color: white;
    width: 100%;
    height: 66px;
    text-decoration: none;
    &__icon {
        margin-right: 10px;
    }
    &__text {
        font-family: 'Helvetica Neue';
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 2px;
        line-height: 19px;
        font-style: normal;
        color: black;
        text-decoration: none;
    }

    @media screen and (max-height: 480px) {
        height: 50px;
    }
    @media screen and (max-height: 380px) {
        height: 30px;
    }
}

.menu__buttons:hover {
    background-color: var(--light-pink-color);
}
