.impact-container-contributions {
    background-color: var(--white-color);

    &__switch-wrapper {
        display: flex;
        width: 100%;
        gap: 12px;
    }

    &__switcher {
        width: 50%;
        text-align: center;
        padding: 10px;
        border-radius: 20px;
        border: none;
        background-color: transparent;
        color: var(--pink-color);
        border: 1px solid var(--pink-color);
        cursor: pointer;
        font-size: 1rem;
        margin-bottom: 24px;
    }

    &__spiner{
        margin-top: 50px;
    }

    &__date-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        span{
            font-size: 1.2rem;
            font-weight: 600;
        }

        & button {
            width: 30px;
            height: 30px;
            padding: 10px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            background-color: transparent;
            border: 1px solid black;
            cursor: pointer;

            & img {
                width: 18px;
                height: 18px;
            }
        }

        .arrow-next {
            transform: rotate(180deg);
        }
    }

    &__statistic {
        background-color: #7aa0b5;
        border-radius: 32px;
        padding: 20px;
        width: 100%;
    }

    &__rewards {
        margin: 24px 0;
      
        &-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
        }

       
    }

    &__total{
        & h3{
            margin-bottom: 12px;
        }
    }

    & h3 {
        font-weight: 500;
        font-size: 1.25rem;
    }
}

.active-switcher {
    background-color: var(--pink-color);
    color: white;
}

.title-tab {
    font-size: 2rem;
    margin: 10px 0 24px;
    font-family: 'Helvetica Neue';
    font-weight: 500;
}

.statistic-data {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & h6 {
        margin: 0 0 8px;
        font-size: 1.125rem;
        font-weight: normal;
        color: #e1e1e1;
    }
}

.statistic-row {
    display: flex;
    gap: 10px;

    & span {
        line-height: 1;
        color: white;
        font-size: 1.3rem;
        font-weight: 600;
    }
}

.statistic-calculate {
    width: 100%;
    padding: 13px 0px;
    font-size: 1rem;
    color: white;
    cursor: pointer;
    background-color: var(--pink-color);
    border: none;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.total-row{
    display: flex;
    justify-content: center;
    font-size: 2rem;
}
