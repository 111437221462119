.transaction-item {
    padding: 15px 0;
    border-radius: 6px;

    &--list {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom: 1px solid #9b9b9b;

        &:last-child {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            border-bottom: none;
            padding-bottom: 20px;
        }
    }

    &__info {
        display: flex;
        justify-content: space-between;
    }

    &__title {
        display: flex;
        align-items: center;
    }

    &__img {
        width: 15px;
        height: 15px;
        margin-right: 10px;
    }

    &__name {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.02em;
        color: var(--black-color);
    }

    &__status{
        display: flex;
        align-items: center;
        margin-top: 10px;
        line-height: 100%;
        justify-content: space-between;

        & div{
            display: flex;
            gap: 10px;
        }
    }

    &_points {
        display: flex;
        align-items: center;
        font-family: 'Helvetica Neue Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.02em;
        color: var(--black-color);
        // min-width: 72px;
    }

    &_points-img {
        margin-left: 5px;
        width: 18px;
        height: 18px;
    }

    &__type {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 1px;
        color: #9b9b9b;
    }

    &__amount {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.02em;
        color: #000000;
    }

    &__state{
        font-size: 0.8rem;
    }

    // &_currency {
    //     font-family: 'Helvetica Neue Bold';
    //     font-style: normal;
    //     font-weight: 700;
    //     font-size: 16px;
    //     line-height: 26px;
    //     text-align: right;
    //     letter-spacing: 0.02em;
    //     color: var(--black-color);
    //     max-width: 18.5px;
    //     min-width: 30.5px;
    // }
}
// .transaction-item {
//     display: flex;
//     justify-content: space-between;
//     padding: 15px 0;
//     border-radius: 6px;
//     border-bottom-left-radius: 0px;
//     border-bottom-right-radius: 0px;
//     border-bottom: 1px solid #9b9b9b;
//     &:last-child {
//         border-top-left-radius: 0px;
//         border-top-right-radius: 0px;
//         border-bottom-left-radius: 6px;
//         border-bottom-right-radius: 6px;
//         border-bottom: none;
//     }
//     &_title {
//         font-family: 'Helvetica Neue';
//         font-style: normal;
//         font-weight: 400;
//         font-size: 16px;
//         line-height: 26px;
//         letter-spacing: 0.02em;
//         color: var(--black-color);
//         width: 40%;
//     }

//     &_points {
//         font-family: 'Helvetica Neue Bold';
//         font-style: normal;
//         font-weight: 700;
//         font-size: 16px;
//         line-height: 26px;
//         text-align: right;
//         letter-spacing: 0.02em;
//         color: var(--black-color);
//         min-width: 72px;
//     }
//     &_currency {
//         font-family: 'Helvetica Neue Bold';
//         font-style: normal;
//         font-weight: 700;
//         font-size: 16px;
//         line-height: 26px;
//         text-align: right;
//         letter-spacing: 0.02em;
//         color: var(--black-color);
//         max-width: 18.5px;
//         min-width: 30.5px;
//     }
// }
