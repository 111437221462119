.sign-in-container {
    background-color: var(--white-color);
    height: 100%;
    padding: 35px;
    display: flex;
    flex-direction: column;

    &__close-wrapper {
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
    }

    &__body {
        margin-top: 30px;
    }

    &_title {
        font-family: 'Helvetica Neue Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: var(--black-color);
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }

    &__form-container {
        width: 100%;
        margin-top: 35px;
    }

    &__bottom-container {
        margin-top: 40px;
    }

    &__forgot-container {
        display: flex;
        margin: 2rem;
        justify-content: center;
    }

    &_forgot-title {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 1px;
        color: var(--black-color);
        cursor: pointer;
    }
}
