.header-container {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    height: 60px;
    align-items: center;
    border-bottom: 2px solid #ebebeb;
    flex-shrink: 0; 

    &__arrow {
        cursor: pointer;
    }

    &__title {
        font-family: 'Helvetica Neue Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: var(--black-color);
    }

    &__info {
        cursor: pointer;
    }
}
