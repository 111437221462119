.edit-profile-container {
    height: 100%;
    position: relative;
    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        padding: 16px 16px;
    }
    &__text-container {
        width: 100%;
        display: flex;
        justify-content: space-around;
        justify-items: center;
        align-items: center;
    }
    &__title {
        font-family: 'Helvetica Neue Bold';
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 1px;
        color: black;
    }
    &__border {
        border: 1px solid #ebebeb;
    }
    &__photo_edit-photo {
        text-align: center;
    }
    &__photo {
        width: 186px;
        height: 186px;
        border-radius: 100px;
        margin: 30px 0 20px 0;
        object-fit: contain;
        background-color: white;
    }
    &__edit-photo {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    &__text {
        margin: 2px 0 0 15px;
        font-family: 'Helvetica Neue';
        font-weight: 300;
        font-size: 16px;
        letter-spacing: 1px;
        color: black;
        cursor: pointer;
    }
    &__inputs {
        margin: 30px 0 0 10px;
        display: flex;
        flex-direction: column;
        width: 96%;
        height: 44%;
        @media screen and (max-width: 500px) {
            height: 38%;
        }
        .edit-profile-country-dropdown {
            padding-left: 2px;
            padding-bottom: 0;
            color: var(--grey-color); 
            .dropdown_container {
                &__input {
                    &:hover{
                        background-color: #d0dbd8;
                    }
                color: var(--grey-color);                }
            }
        }
    }
    &_input {
        border: 1px solid white;
        border-bottom: 1px solid #ebebeb;
        padding: 0 16px 20px 16px;
        font-family: 'Helvetica Neue';
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 2px;
        height: 35px;
    }
    &_input:focus {
        outline: none;
    }

    &_input:hover {
        background-color: #d0dbd8;
    }
    &-button__container {
        position: relative;
        width: 90%;
        bottom: 0;
        margin: 0 5% 45px 5%;
    }
}

.edit-profile-container__inputs > div > input:hover {
    background-color: #d0dbd8;
}
