.container {
    background: var(--white-color);
    padding-top: 32px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding-bottom: 35px;
    display: flex;
    flex-direction: column;
 
    &__cDropdown {
        .start-screen-country-dropdown {
            .dropdown_container {
                &__label {
                    color: black; 
                }
            }
        }
    }
    .start-screen-country-dropdown.error {
        border-bottom: 1px solid red;
      }
      
      .error-message {
        position: relative;
        bottom: 1rem;
        color: red;
        font-size: 14px;
        margin-top: 8px;
      }

    &__close-wrapper {
        padding: 0 39px;
        display: flex;
        justify-content: flex-end;
    }
    &_close {
        color: var(--white-color);
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 48px;
    }

    &__body {
        padding: 0 39px 0;
        margin: auto auto;
        transform: translateY(-32px);
        width: 100%;
    }

    &__logo-container {
        margin-bottom: 2.5rem;
        text-align: center;
    }
    &_logo {
        height: 60px;
    }

    &_title {
        font-family: 'Helvetica Neue Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: var(--black-color);
        margin-top: 40px;
    }

    &__social-buttons-container {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin: 0 auto;
        margin-top: 30px;
    }

    &__or-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }
    &__or-line {
        height: 1px;
        width: 50%;
        background-color: var(--black-color);
    }
    &_or-text {
        font-family: 'Helvetica Neue Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: var(--black-color);
        margin: 0 10px;
    }

    &__sign-up-wrapper {
        width: 80%;
        margin: 0 auto;
        margin-top: 20px;
    }

    &_description {
        color: var(--white-color);
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        margin-top: 20px;
    }

    &_already-text {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        color: var(--black-color);
        margin-top: 20px;
    }

    &_sign-in {
        font-weight: 600;
        cursor: pointer;
    }
}
.container-close {
    cursor: pointer;
}
