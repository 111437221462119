.button-container {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 66px;
    padding: 0 10px 0 7px;
    text-align: center;
    border: none;
    background-color: white;
    align-items: center;
    border-bottom: 1px solid rgba(155, 155, 155, 0.2);
    &__text {
        margin: 0px 0 0 7px;
        font-family: 'Helvetica Neue';
        font-size: 16px;
        letter-spacing: 1px;
        align-self: center;
        color: black;
    }
    &__icon-text {
        display: flex;
        flex-direction: row;
    }
    &__icon {
        margin: 3px 3px 0 0;
    }
    &__icon-edit {
        margin: 2px -2px 0 0;
    }
    &__arrow {
        margin: 3px 0 0 0;
    }
}
.button-container:hover {
    background-color: var(--light-pink-color);
}
