.photo {
    border-radius: 15%;
    border: 1px solid black;
    width: 200px;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    position: relative;

    @media (max-width: 440px) {
        width: 100%;
        aspect-ratio: 1 / 1;
    }

    &-container {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: 75%;
        cursor: pointer;
        border-bottom: 1px solid black;
        padding: 10px 10px 0px 10;
        background-size: cover;
        background-position: center;
    }

    &-middle-section {
        height: 65%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    &-logo {
        max-height: 250px;
        height: 100%;
        object-fit: contain;
        max-width: 100%;
    }

    &-name {
        font-size: 1.3rem;
        font-family: 'Helvetica', sans-serif;
        text-align: center;

        @media (max-width:440px){
            font-size: 5vw;
        }
    }

    &-percentage {
        position: absolute;
        top: 3.5%;
        right: 3.5%;
        z-index: 3;
        background-color: #ffa9a3;
        color: white;
        border-radius: 50%;
        padding: 10%;
        font-weight: bold;
        font-size: 0.8rem;
        width: 20%;
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width:440px){
            font-size: 3vw;
        }
    }

    .percentage-fixed {
        display: flex;
        gap: 3px; 
        flex-direction: column;
        line-height: 0.8;
        background-color: #7aa0b5;
        font-size: 0.8rem;
        span:first-child {
            font-size: 0.8rem; 
            font-weight: bold; 
        }
    }

    &-site {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        height: 25%;

        &:hover {
            background-color: #ffebea;
        }

        &:hover > button {
            color: #ffa9a3;
        }

        & > button {
            font-weight: 600;
            cursor: pointer;
            text-decoration: none;
            color: black;
            margin: 0;
            background-color: transparent;
            border: none;
            height: fit-content;
        }
    }
}

.photo-site-sm{
    font-size: .85rem;

    @media (max-width: 440px) {
        font-size: 3vw;
    }
}

.photo-site-bg{
    font-size: 1rem;

    @media (max-width: 440px) {
        font-size: 3.5vw;
    }
}