.terms-of-use {
    &__content {
        padding: 20px 15px;

        p {
            text-align: justify;
        }

        h2 {
            margin: 10px 0;
            font-weight: bold;
            font-size: 1.3rem;
        }
    }
}
