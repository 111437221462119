.ambassador-container {
    font-family: 'Helvetica Neue';
    height: 100%;
    position: relative;
    line-height: 1.6;
    &__content {
        padding: 20px 16px;
    }

    &__description {
        strong {
            display: block; 
            margin-bottom: 3rem; 
          }
          p {
            margin-bottom: 1rem;
          }
    padding: 1rem;
    text-align: center;
    }
   

    &__action {
        width: 70%;
        margin: auto;
        margin-top: 5%;
      
    }
}


.share-options{
    &__header{
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
        color: var(--black-color);
    }
    &__option{
        display: flex;
        align-items: center;
        height: 66px;
        cursor: pointer;
        border-bottom: 1px solid rgba(155, 155, 155, 0.2);
        &:hover{
            background-color: var(--light-pink-color);
        }
        & > *{
            margin: 5%;
        }
    }
}



.ambassador-balance {
    padding: 16px 21px;
    border-bottom: 2px solid var(--light-gray-color);

    &__info {
        display: flex;
        margin: 8px;
        justify-content: space-between;
    }
}
