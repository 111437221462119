.calculate-income {
    &__content {
        padding: 15px 16px;
    }

    &__slider-group {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-bottom: 24px;
    }

    &__result {
        background-color: #7aa0b5;
        padding: 5px 20px 50px;
        display: flex;
        flex-direction: column;
        border-radius: 32px;
        color: white;
        padding-top: 20px;

        & h2 {
            margin-bottom: 50px;
        }
    }

    & input[type='range'] {
        width: 100%;
        appearance: none; 
        height: 10px;
        background-color: var(--gray-color); 
        border-radius: 30px;
    }

    & input[type='range']::-webkit-slider-runnable-track {
        background: var(--pink-color);
        border-radius: 30px;
        display: flex;
        height: 10px;
    }

    & input[type='range']::-webkit-slider-thumb {
        background: #7aa0b5;
        width: 20px; /* Виправ розмір до більш звичного */
        height: 20px; /* Висота також потрібна */
        border-radius: 50%;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -5px; /* Вирівнювання по осі */
    }

    & input[type='range']::-moz-range-track {
        background: var(--pink-color);
        border-radius: 30px;
        height: 10px;
    }

    & input[type='range']::-moz-range-thumb {
        background: #7aa0b5;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        cursor: pointer;
    }

    & input[type='range']::-ms-track {
        width: 100%;
        height: 10px;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    & input[type='range']::-ms-fill-lower {
        background: var(--pink-color);
        border-radius: 30px;
    }

    & input[type='range']::-ms-fill-upper {
        background: var(--gray-color);
        border-radius: 30px;
    }
}

.slider-label {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-self: center;
    margin-bottom: 12px;
}

.result-circle {
    background-color: var(--pink-color);
    width: 212px;
    height: 212px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-size: 2rem;
    text-align: center;
    font-weight: normal;
}
