.follow-screen {
    display: flex;
    flex-direction: column;
    height: 100%;

    &_count {
        text-align: center;
        margin: 10px 0 10px 0;
        font-family: 'Helvetica Neue Bold';
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.02em;
        color: black;
    }
    &_container-global {
        flex-wrap: wrap;
        display: flex;
        gap: 40px;
        height: 100%;
        margin: 0 15px;
    }
    &__container-global {
        height: fit-content;
        display: flex;
        flex-direction: column;
        margin: 0 15px 0 15px;
    }
    &__container {
        margin: 15px 0px 20px;
        display: grid;
        grid-template-columns: repeat(2, 48%);
        gap: 10px;
    }
    &__new-brand {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 100%;
        aspect-ratio: 1 / 1;
        padding-bottom: 10px;
        border: 1px solid #9b9b9b;
        border-radius: 30px;

        &_title {
            width: fit-content;
            max-width: 88px;
            color: #9b9b9b;
            font-family: 'Helvetica Neue Bold';
            font-size: 15px;
            text-align: center;
            text-transform: uppercase;
        }
    }
}
