.follow-brand {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100%;
    aspect-ratio: 1 / 1;
    border: 1px solid black;
    border-radius: 15%;
    overflow: hidden;

    &_img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &_logo {
        width: 100%;
        object-fit: fill;
    }

    &_name {
        position: absolute;
        top: 44%;
        text-align: center;
        font-weight: 700;
        font-size: 1rem;
    }

    &_unfollow {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
