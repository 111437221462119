.faq-container {
    margin: auto;
    max-width: 800px;
    font-family: 'Helvetica Neue';
    color: #333;
  
    // @media (max-width: 768px) {
    //   padding: 1.5rem 0.5rem;
    // }
}

.faq__section {
    padding-bottom: 1rem;
    &-title {
      padding-top: 0.5rem;
      padding-left: 1rem;
      text-align: center;
      font-size: 20px;  
      font-weight: 600;
      border-bottom: 1px solid #ddd;

      padding-bottom: 0.5rem;
    }
}

.faq__item {
    
    border-bottom: 1px solid #ddd;
  
    &:last-child {
      border-bottom: none;
    }
  
    .faq__question {
      padding: 1rem;
      font-size: 1.2rem;
      user-select: none;
      font-weight: 500;
      color: #444;
      cursor: pointer;  
      &:hover {
        background-color: #ffd8d683; 
      }
    }
  
    .faq__answer {
      margin-top: 0.5rem;
      margin-left: 0.5rem;
      font-size: 1rem;
      color: #4E6E81;
      line-height: 1.6;
      padding-left: 2rem;
      border-left: 3px solid #ddd;
    }
  }
  
