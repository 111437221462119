.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    &__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: transparent;
    }

    &__content {
        background: #fff;
        padding: 20px;
        border-radius: 8px;
        position: relative;
        z-index: 1001;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        max-width: 500px;
        width: 90%;
    }

    &__close {
        position: absolute;
        top: 10px;
        right: 10px;
        background: transparent;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
    }
}
