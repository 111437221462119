.mobile-menu {
    &__container {
        z-index: 100;
        position: fixed;
        display: flex;
        flex-direction: column;
        padding: 36px 5%;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        background: var(--pink-color);
        @media screen and (min-width: 1100px) {
            display: none;
        }
    }

    &__header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    &__close {
        border: none;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 43px;

        & img {
            height: 100%;
        }
    }

    &__user {
        width: fit-content;
        border-radius: 1000px;

        &.login {
            background: none;
        }

        & p {
            color: #000;
            font-size: 20px;
            font-family: var(--header-font-family);
        }
    }

    &__content {
        position: relative;
        margin-top: 30px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__list {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        transform: translateY(-10px);

        & a {
            color: #000;
            position: relative;
            padding: 6px 4px;
            text-decoration: none;
            text-align: center;
            font-size: 24px;
            font-family: var(--header-font-family);

            &.active-item {
                border-bottom: 1px solid #000;
            }
        }
    }
}
