@import './fonts.scss';

body {
    overflow-y: hidden;
}

:root {
    --primary-color: #1e4037;
    --white-color: #ffffff;
    --black-color: #000000;
    --grey-color: #9b9b9b;
    --light-blue-color: #83a0b0;
    --light-gray-color: #ebebeb;
    --gasoline-color: #155f82;
    --gasoline-dark-color: #0b3447;
    --gasoline-light-color: #0e445d3e;
    --pink-color: #ffa9a3;
    --light-pink-color: #ffd8d683;
    --button-border-radius: 7px;
    --header-font-family: 'Barlow', sans-serif;
}
