.select-container {
    background-color: var(--white-color);
    min-height: 100%;
    padding: 5px 20px 40px 35px;


    &__back-wrapper {
        display: flex;
        justify-content: flex-start;
        cursor: pointer;
    }

    &__content {
        margin-top: 30px;
    }

    &_title {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: var(--black-color);
    }

    &__interests-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        margin-top: 30px;
    }

    &__bottom-wrapper {
        margin-top: 40px;
        cursor: pointer;
    }
}
