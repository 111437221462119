.language-dropdown {
    position: relative;
    display: inline-block;
    color: white;
    padding: 0px 15px;
    min-width: 150px;

    &-desktop {
        @media screen and (max-width: 500px) {
            display: none;
        }
    }

    &-mobile {
        background: black;
        margin-top: 40px;

        @media screen and (min-width: 500px) {
            display: none;
        }
    }

    &__button {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 5px;
        border-radius: 4px;
        position: relative;
        gap: 7px;
        width: 100%;

        &-mobile {
            justify-content: space-evenly;
        }

        > img {
            width: 20px;
        }

        > h5 {
            font-size: 1.2rem;
            font-weight: normal;
        }

        .arrow-down {
            margin-left: 8px;
            border: solid #ffa9a3;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
        }
    }

    &__list {
        position: absolute;
        top: 100%;
        left: 0;
        background: black;
        list-style: none;
        padding: 5px 5px 10px;
        margin: 0;
        z-index: 4;
        width: 100%;

        li {
            padding: 5px 10px;
            cursor: pointer;
            font-size: 1.2rem;

            &:hover {
                color: #ffa9a3;
            }
        }
    }
}
