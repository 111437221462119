.container {
    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 16px;
        height: 60px;
        min-height: 60px;
    }
    &__backarrow {
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    &__text-container {
        width: 100%;
        display: flex;
        justify-content: space-around;
        justify-items: center;
        align-items: center;
    }
    &__text {
        font-family: 'Helvetica Neue Bold';
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 1px;
        color: black;
    }
    &__border {
        border: 1px solid #ebebeb;
    }
}
