.menu__porfile-info {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;

    box-sizing: border-box;

    &__content {
        display: flex;
        align-items: center;
        padding: 0 16px;
        height: 60px;
    }
    &__backarrow {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 24px;
        height: 32px;
        justify-content: center;

        & img {
            position: absolute;
        }
    }

    &__center-content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: 100%;
    }

    &_image {
        height: 40px;
        width: 40px;
        min-width: 40px;
        border-radius: 100px 100px 0 100px;
        object-fit: cover;
        margin-top: -6px;
        background-color: var(--pink-color);
    }
    &_name-points {
        display: flex;
        height: 30px;
        justify-content: center;
        align-items: center;
        &__border {
            height: 16.5px;
            border: 1px solid rgba(0, 0, 0, 0.2);
        }
        &__name {
            font-family: 'Helvetica Neue Bold';
            font-style: normal;
            font-size: 16px;
            font-weight: 700;
            margin: 0 10px 0 0;
            text-transform: uppercase;
            word-break: break-all !important;
            letter-spacing: 0.002em;
            color: black;
        }
        &__points {
            display: flex;
            justify-content: space-between;
            justify-content: center;
            align-items: center;
            margin: 0 0 0 10px;
            align-self: center;
        }

        &__points-text {
            font-family: 'Helvetica Neue';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            text-align: center;
            color: #000000;
        }
    }

    &__bottom-line {
        width: 100%;
        border: 1px solid #ebebeb;
    }
}
