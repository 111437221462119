.shop-screen-button {
    cursor: pointer;
    padding: 5px 15px;
    font-family: 'Helvetica Neue';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0001em;
    background-color: white;
    border-radius: 24px;
    border: 1px solid var(--pink-color);
    height: 34px;
    color: var(--pink-color);
    &:hover {
        background-color: var(--light-pink-color);
    }
}
.shop-screen-button-active {
    cursor: pointer;
    padding: 5px 15px;
    font-family: 'Helvetica Neue';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0001em;
    background-color: var(--pink-color);
    color: white;
    border-radius: 24px;
    border: 1px solid var(--pink-color);
    height: 34px;
}
.shop-screen-button-search {
    cursor: pointer;
    width: 92px;
    height: 40px;
    padding: 8px 24px;
    font-family: 'Helvetica Neue Bold';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0001em;
    // justify-content: center;
    background-color: var(--pink-color);
    // background-color: #F7D4C7;
    // color: black;
    color: white;
    border-radius: 24px;
    // border: 0px solid #F7D4C7;
    border: 1px solid var(--pink-color);
    &:hover {
        color: var(--pink-color);
        background-color: var(--light-pink-color);
        cursor: pointer;
    }
}
