.DeleteAcc-container {
    font-family: 'Helvetica Neue';
    height: 100%;
    position: relative;
    line-height: 1.6;
    &__content {
        padding: 20px 16px;
    }

    &__description {
        strong {
            display: block; 
            margin-bottom: 3rem; 
          }
          p {
            margin-bottom: 1rem;
          }
    padding: 1rem;
    }
   &__label {
text-align: center;   }

    &__action {
        display: flex;
        gap: 5%;
        width: 70%;
        margin: auto;
        margin-top: 5%;
      
    }
}

