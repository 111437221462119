.dropdown_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Helvetica Neue';
    color: rgb(117, 117, 117);
    width: 100%;
    margin-bottom: 25px;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 5px;
  
    &__label {
      font-size: 14px;
      margin-right: 10px;
      white-space: nowrap;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 2px;
    }
  
    &__input {
      letter-spacing: 2px;
      border: none;
      outline: none;
      padding: 10px 3px;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      color: rgb(117, 117, 117);
      width: 100%;
      text-align: center;
  
      

      & > option {
        padding: 10px;
        color: var(--grey-color);

      }
    }
  }
 