.toast {
    position: absolute;
    cursor: default;
    bottom: 19%;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--gasoline-dark-color);
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    font-size: 0.9rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease, fadeOut 0.3s ease 1.7s;
    z-index: 1000;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateX(-50%) translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateX(-50%) translateY(0);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
