.details-container {
    height: 100%;

    &__hero-wrapper {
        height: 25%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 20px;
        background-position-y: center;
        background-color: #000;
    }

    &__close-wrapper {
        cursor: pointer;
        padding: 0 16px 0 17px;
    }

    &_title {
        font-family: 'Helvetica Neue Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.02em;
        color: var(--white-color);
        margin-top: 6%;
    }

    &__content {
        padding: 35px 30px 130px;
    }
    &__content-wrapper {
        height: 90%;
        margin-bottom: 20px;
    }

    &__button-wrapper {
        height: 10%;
    }
}

.details-container__content-wrapper > p > img {
    width: 100%;
}

.details-container__content-wrapper > p > span {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
}
