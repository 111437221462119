.settings-container {
    width: 100%;
    height: 100%;
    position: relative;
    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        padding: 21px 16px 11px 16px;
    }
    &__text-container {
        width: 100%;
        display: flex;
        justify-content: space-around;
        justify-items: center;
        align-items: center;
    }
    &__text {
        font-family: 'Helvetica Neue Bold';
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 1px;
        color: black;
    }
    &__border {
        border: 1px solid #ebebeb;
    }
    &__buttons {
        position: relative;
        width: 100%;
    }
}
