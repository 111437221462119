.modal {
    width: 100%;
    overflow-x: hidden;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: flex-end;
    height: 100%;
    top: 0;
    right: 0;
    position: fixed;
    z-index: 1000;
    &__modal-container_2 {
        width: 25%;
        z-index: 1;
        background-color: var(--white-color);
        border-left: 3px solid var(--primary-color);
        overflow: auto;
        transition: 0.4s;
        transform: scale(0, 0);
        transform-origin: 100% 0;
       
        @media screen and (max-width: 1750px) {
            width: 30%;
        }
   
        @media screen and (max-width: 1100px) {
            width: 40%;
        }

        @media screen and (max-width: 800px) {
            width: 50%;
        }

        @media screen and (max-width: 600px) {
            width: 70%;
        }

        @media screen and (max-width: 500px) {
            width: 100%;
        }
    }
    &__modal-container_1 {
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: 0.4s;
        opacity: 0;
    }

    &.active &__modal-container_2 {
        transform: scale(1, 1);
    }
    &.active &__modal-container_1 {
        opacity: 1;
    }
}
